import { UserMenuProps } from '@nextbusiness/infinity-ui'
import { DemoContext, DemoContextType } from 'components/demo/DemoBanner'
import NetworkingConstants from 'networking/NetworkingConstants'
import { useContext } from 'react'
import { useRootStore } from 'stores/RootStoreContext'

/** Requires use in an **observed** component since it uses useRootStore hook */
export const useUserMenu = () => {
  const { authenticationStore } = useRootStore()
  const demoContext = useContext(DemoContext)

  if (import.meta.env.VITE_DEMO_MODE) {
    return makeDemoUserMenu(demoContext)
  }
  const userMenu: UserMenuProps = {
    baseURL: NetworkingConstants.HOME,
    name: authenticationStore.user?.firstname ?? '',
    currentOrganisation: {
      name: authenticationStore.currentOrganisationName,
      id: authenticationStore.fabricOrganisationIdentifier!,
      onClick: () => {},
    },
    allOrganisations: authenticationStore.organisations.map((organisation) => ({
      name: organisation.name,
      id: organisation.IDForAPI,
      onClick: () =>
        authenticationStore.switchToOrganisation(organisation.IDForAPI),
    })),
  }
  return userMenu
}

const makeDemoUserMenu = (demoContext: DemoContextType) => {
  const userMenu: UserMenuProps = {
    baseURL: NetworkingConstants.HOME,
    name: 'Svenja',
    currentOrganisation: { name: 'Examply', id: 'examply', onClick: () => {} },
    allOrganisations: [
      { name: 'Examply', id: 'examply', onClick: () => {} },
      {
        name: 'Demo zurücksetzen',
        id: 'reset-action',
        onClick: () => demoContext.resetDemo?.(),
      },
    ],
  }
  return userMenu
}

import { Button, Flex } from '@nextbusiness/infinity-ui'
import { IconSize } from '@nextbusiness/infinity-ui-icons'
import Lockup from 'components/text/Lockup'
import MixpanelAnalytics from 'integrations/MixpanelProductAnalytics'
import SprigIntegration from 'integrations/SprigIntegration'
import { useEffect, useState } from 'react'
import { useVendorInvoiceEditor } from '../detail/VendorInvoiceEditorContext'
import { useVendorInvoiceCreator } from '../vendor-invoice-creation/VendorInvoiceCreationContext'
import LiveCaptureInvoiceBanner from './LiveCaptureInvoiceBanner'

const LiveCaptureAnalysedInvoiceBanner = () => {
  const { liveCaptureTransformer } = useVendorInvoiceCreator()

  const { makeEdit, invoiceDraft } = useVendorInvoiceEditor()
  const [originalInvoice, setOriginalInvoice] = useState<typeof invoiceDraft>()

  const [hasApplied, setHasApplied] = useState<boolean>(false)
  const [hasDismissed, setHasDismissed] = useState<boolean>(false)

  const applyLiveCapture = () => {
    if (!liveCaptureTransformer) return

    setOriginalInvoice(invoiceDraft)
    makeEdit(liveCaptureTransformer.invoiceSuggestion)
    setHasApplied(true)

    SprigIntegration.sprig?.('track', 'LIVE_CAPTURE_USED')
    MixpanelAnalytics.event('Live capture applied - Vendor invoice')
  }

  const revertLiveCapture = () => {
    if (originalInvoice) {
      makeEdit({
        openingDate: undefined,
        dueDate: undefined,
        title: undefined,
        creditor: undefined,
        positions: [],
        ...originalInvoice,
      })
      setOriginalInvoice(undefined)
      setHasApplied(false)
    }
  }

  useEffect(() => {
    applyLiveCapture()
    MixpanelAnalytics.event('Live Capture applied in vendor invoice')
  }, [liveCaptureTransformer])

  if (hasDismissed) return null

  return (
    <LiveCaptureInvoiceBanner applied={hasApplied}>
      <Lockup icon='ocr'>
        Live Capture hat einen Rechnungsvorschlag vorbereitet
      </Lockup>
      <Flex gap='tiny'>
        {!hasApplied ? (
          <Button variant='primary' onClick={applyLiveCapture}>
            Ausfüllen
          </Button>
        ) : (
          <Button iconLeft='undo' onClick={revertLiveCapture}>
            Rückgängig
          </Button>
        )}
        <Button
          iconOnly='dismiss'
          iconSize={20 as unknown as IconSize}
          variant='quaternary'
          onClick={() => setHasDismissed(true)}
          tooltip='Schliessen'
        />
      </Flex>
    </LiveCaptureInvoiceBanner>
  )
}

export default LiveCaptureAnalysedInvoiceBanner

import { TemplatePreviewProps } from './TemplatePreview'

const TemplatePreviewRecipient = (props: TemplatePreviewProps) => (
  <div className='template-page-recipient'>
    <div className='envelope-window'>
      {!props.template.disableSenderRowLine && (
        <div className='envelope-sender-row'>
          {props.template.organisationName}
          {props.template.organisationName && props.template.addressLine
            ? `, `
            : ''}
          {props.template.addressLine}
          {(props.template.addressLine || props.template.organisationName) &&
          (props.template.zipCode || props.template.city)
            ? ', '
            : ''}
          {props.template.zipCode} {props.template.city}
        </div>
      )}
      <div className='envelope-recipient'>
        <span>Frau</span>
        <span>Sabine Holzberg</span>
        <span>Börsenstrasse 10</span>
        <span>8000 Zürich</span>
      </div>
    </div>
  </div>
)

export default TemplatePreviewRecipient

import { DetailedVATPeriod, VATReport } from 'model/VAT'
import DocumentsPage, { DocumentsPagePaths } from 'pages/DocumentsPage'
import { useCallback } from 'react'
import VATReportActions from './VATReportActions'
import VATReportStatus from './VATReportStatus'

interface VATReportActionsProps {
  period: DetailedVATPeriod
  page: DocumentsPage
  openSettlementModal: () => void
}

const VATReportFooter = (props: VATReportActionsProps) => {
  const { period } = props

  const isPeriodDue = period.end < Date.now() && !period.wasSettled

  const periodHasCriticalCalculationMismatch =
    period.report['299'].amount !== period.report['379'].amount

  const periodHasInvalidAmounts = useCallback(() => {
    for (const key in period.report) {
      if (!period.report.hasOwnProperty(key)) continue
      if (period.report[key as keyof VATReport].amount < 0) return true
    }
    return false
  }, [period.report])

  const isReportInvalid =
    periodHasCriticalCalculationMismatch || periodHasInvalidAmounts()

  return (
    <div className='report-footer'>
      <VATReportStatus
        isPeriodDue={isPeriodDue}
        isReportInvalid={isReportInvalid}
        period={period}
      />
      <VATReportActions
        isPeriodDue={isPeriodDue}
        isReportInvalid={isReportInvalid}
        openSettlementModal={props.openSettlementModal}
        openTransactionsPage={() =>
          props.page.open(
            DocumentsPagePaths.VATReportTransactions,
            props.period._id
          )
        }
      />
    </div>
  )
}

export default VATReportFooter

import { Flex, Text } from '@nextbusiness/infinity-ui'

const VATUnavailablePeriodsSection = () => (
  <Flex className='vat-period'>
    <div className='period-title'>Neuere Perioden</div>
    <div className='period-content'>
      <Text type='inline'>
        Zuerst müssen ältere Perioden vollständig abgerechnet werden, bevor
        neuere Perioden abgerechnet werden können.
      </Text>
    </div>
  </Flex>
)

export default VATUnavailablePeriodsSection

import * as Sentry from '@sentry/react'
import NetworkingConstants from 'networking/NetworkingConstants'

class SentryIntegration {
  public static get shouldInitialise() {
    return Boolean(import.meta.env.PROD || import.meta.env.VITE_SENTRY_ENV)
  }

  public static initialise() {
    if (import.meta.env.PROD || import.meta.env.VITE_SENTRY_ENV) {
      const traceSamples = import.meta.env.VITE_SENTRY_TRACE_SAMPLE_RATE
      const hasUnlimitedReplayTraces =
        Date.now() < new Date('2023-08-03').getTime()

      Sentry.init({
        dsn: import.meta.env.VITE_SENTRY_DSN,
        integrations: [
          Sentry.browserTracingIntegration(),
          Sentry.reactRouterV5BrowserTracingIntegration({
            history,
          }),
          Sentry.replayIntegration({
            maskAllText: true,
            maskAllInputs: true,
            blockAllMedia: true,
          }),
        ],
        tracesSampleRate: traceSamples ? parseFloat(traceSamples) : 1,
        tracePropagationTargets: [
          'localhost',
          NetworkingConstants.HOST,
          NetworkingConstants.FABRIC_HOST,
        ],
        replaysSessionSampleRate: hasUnlimitedReplayTraces ? 1 : 0.05,
        replaysOnErrorSampleRate: 1.0,
        environment:
          import.meta.env.VITE_SENTRY_ENV ||
          (import.meta.env.PROD ? 'production' : 'development'),
      })
    }
  }
}

export default SentryIntegration

import {
  AmountMode,
  IAnalysedDocumentPosition,
  IDocumentAnalysis,
  TaxCode,
} from '@nextbusiness/infinity-finance-api'
import { ROUNDING_DIFFERENCE_SYSTEM_ACCOUNT_NUMBER } from 'invoices/shared-components/PayInvoiceModal'
import { IPosition, IVendorInvoiceDraft } from 'model/VendorInvoice'
import { generateRandomId } from 'utility/StringUtilities'
import TaxUtilities from 'utility/TaxUtilities'
import Utilities from 'utility/Utilities'
import VendorInvoiceEditorUtilities from '../detail/VendorInvoiceEditorUtilitites'
import LiveCaptureContactSuggestor from './LiveCaptureContactSuggestor'

/**
 * Transforms a document analysis result into a vendor invoice suggestion.
 */
class LiveCaptureInvoiceTransformer {
  private documentAnalysis: IDocumentAnalysis
  private shouldSuggestVAT: boolean

  /**
   * Create a new Live Capture invoice transformer based on
   * a document analysis result. The analysis result should at least
   * contain positions and a total amount for ideal results.
   */
  constructor(documentAnalysis: IDocumentAnalysis, shouldSuggestVAT: boolean) {
    this.documentAnalysis = documentAnalysis
    this.shouldSuggestVAT = shouldSuggestVAT
  }

  /**
   * The transformed invoice suggestion based on the available information
   * from the document analysis result.
   */
  public get invoiceSuggestion(): Partial<IVendorInvoiceDraft> {
    const contactSuggestor = new LiveCaptureContactSuggestor(
      this.documentAnalysis
    )
    const { positions, amountMode } = this.suggestPositions()
    return Utilities.onlyDefinedProperties({
      openingDate: this.documentAnalysis.documentDate,
      dueDate: this.documentAnalysis.documentDueDate,
      title: this.documentAnalysis.title,
      creditor: contactSuggestor.suggestedContactId,
      positions,
      amountMode,
    })
  }

  public get newContactSuggestion() {
    const contactSuggestor = new LiveCaptureContactSuggestor(
      this.documentAnalysis
    )
    return contactSuggestor.newContactSuggestion
  }

  /**
   * Suggests positions for the vendor invoice draft, adjusted for missing
   * tax codes and other differences from the invoice total amount.
   * @returns The suggested positions.
   */
  private suggestPositions(): {
    positions: Partial<IPosition>[]
    amountMode?: AmountMode
  } {
    let positions = [...this.initialPositions]

    const { positions: adjustedPositions, amountMode } =
      this.adjustPositionsForTaxCode(positions)

    positions = this.adjustPositionsForDifferences(
      adjustedPositions,
      amountMode
    )
    return { positions, amountMode }
  }

  /**
   * The total amount of the invoice, as detected by the document analysis.
   */
  private get totalAmount(): number {
    return this.documentAnalysis.amountTotal ?? 0
  }

  /**
   * Gets the initial positions for the vendor invoice draft.
   * If no positions are detected, a single position with the total amount is returned.
   * @returns The initial positions.
   */
  private get initialPositions(): Partial<IPosition>[] {
    const detectedPositions = this.detectedPositions()
    if (detectedPositions.length === 0) {
      return [{ quantity: 1, singleAmount: this.totalAmount }]
    }
    return detectedPositions.map((position) => ({
      renderId: generateRandomId(),
      ...Utilities.onlyDefinedProperties(position),
    }))
  }

  /**
   * Adjusts positions for the tax code based on the total amount, if
   * the difference is detected to be the tax amount.
   */
  private adjustPositionsForTaxCode(positions: Partial<IPosition>[]): {
    positions: Partial<IPosition>[]
    amountMode?: AmountMode
  } {
    if (!this.shouldSuggestVAT) return { positions }

    const difference = this.missingDifference(positions)
    const taxAmount = this.documentAnalysis.amountTax ?? 0
    const isMissingTaxInTotal = Math.abs(difference - taxAmount) < 5
    if (isMissingTaxInTotal) {
      // We are missing the tax amount in the total, which means the invoice
      // positions are likely using net amounts.
      return { positions, amountMode: AmountMode.Net }
    }
    return { positions }
  }

  /**
   * Calculates the missing difference between the detected invoice total
   * and the sum of the detected positions. Negative values are clamped to 0.
   */
  private missingDifference(positions: Partial<IPosition>[]): number {
    return Math.max(
      this.totalAmount -
        VendorInvoiceEditorUtilities.sumOfPositionsWithVAT(
          positions,
          AmountMode.Gross
        ),
      0
    )
  }

  /**
   * Adds an additional position to the positions to adjust for any
   * remaining differences between the total amount and the sum of the positions.
   */
  private adjustPositionsForDifferences(
    positions: Partial<IPosition>[],
    amountMode?: AmountMode
  ): Partial<IPosition>[] {
    if (amountMode === AmountMode.Net) {
      return positions
    }
    const difference = this.missingDifference(positions)
    const positionWithMissingAmount = positions.find(
      (position) => !position.singleAmount
    )
    if (difference <= 0) {
      return positions
    } else if (positionWithMissingAmount) {
      return positions.map((position) =>
        position === positionWithMissingAmount
          ? { ...position, singleAmount: difference }
          : position
      )
    } else {
      return this.adjustedWithDifferencePosition(positions, difference)
    }
  }

  /**
   * Returns an adjusted list of positions with an additional position
   * that represents the remaining difference.
   */
  private adjustedWithDifferencePosition(
    positions: Partial<IPosition>[],
    difference: number
  ): Partial<IPosition>[] {
    let differencePosition: Partial<IPosition> = {
      singleAmount: difference,
      quantity: 1,
      renderId: generateRandomId(),
    }
    if (differencePosition.singleAmount! < 5) {
      differencePosition = {
        ...differencePosition,
        text: 'Rundungsdifferenz',
        contraAccount: ROUNDING_DIFFERENCE_SYSTEM_ACCOUNT_NUMBER,
      }
    }
    return [...positions, differencePosition]
  }

  /**
   * The transformed detected positions from the document analysis result.
   */
  private detectedPositions(): Partial<IPosition>[] {
    return this.filterOutUselessPositions(
      this.documentAnalysis.positions?.map(this.mapDetectedToInvoicePartial) ??
        []
    )
  }

  /**
   * Maps an analysed document position to a partial position for the vendor invoice draft.
   */
  private mapDetectedToInvoicePartial = (
    position: IAnalysedDocumentPosition
  ): Partial<IPosition> => {
    const totalAmount = position.totalAmount
    const quantity = position.quantity ?? 1
    const singleAmount =
      totalAmount && totalAmount / quantity
        ? totalAmount / quantity
        : position.singleAmount

    return {
      totalAmount,
      singleAmount,
      quantity,
      text: position.text,
      taxCode:
        this.inferredTaxCodeFromPosition(position) ||
        this.inferredGlobalTaxCode(),
      contraAccount: position.contraAccount,
    }
  }

  /**
   * Filters out positions that have no single amount or text.
   */
  private filterOutUselessPositions(
    positions: Partial<IPosition>[]
  ): Partial<IPosition>[] {
    return positions.filter(
      (position) => !!position.singleAmount || !!position.text
    )
  }

  /**
   * Infers the tax code from an analysed document position, based on
   * its calculated tax rate.
   */
  private inferredTaxCodeFromPosition(
    position: IAnalysedDocumentPosition
  ): TaxCode {
    if (!this.shouldSuggestVAT) return TaxCode.None
    const taxRate = this.calculateTaxRateFromPosition(position)
    if (!taxRate) return TaxCode.None
    return TaxUtilities.suggestedExpenseTaxCodeForRate(taxRate)
  }

  /**
   * Calculates the tax rate from an analysed document position.
   * @param position The analysed document position.
   * @returns The calculated tax rate.
   */
  private calculateTaxRateFromPosition(
    position: IAnalysedDocumentPosition
  ): number | undefined {
    if (position.taxRate) return position.taxRate
    if (position.taxAmount && position.singleAmount) {
      return (position.taxAmount / position.singleAmount) * 100
    }
    return undefined
  }

  /**
   * Infers a possible invoice-wide tax code using the total tax amount.
   * @returns The inferred global tax code, if possible.
   */
  private inferredGlobalTaxCode(): TaxCode {
    const taxRate =
      this.documentAnalysis.taxRate ?? this.calculateGlobalTaxRate()
    if (taxRate) return TaxUtilities.suggestedExpenseTaxCodeForRate(taxRate)

    return TaxCode.None
  }

  /**
   * Calculates the global tax rate based on the document analysis result,
   * rounded to one decimal place (e.g. 7.7, 8.1).
   * May not be a valid tax rate.
   */
  private calculateGlobalTaxRate(): number | undefined {
    if (!this.canCalculateGlobalTaxRate) return

    const taxAmount = this.documentAnalysis.amountTax!
    const totalAmount = this.documentAnalysis.amountTotal!
    const netAmount = totalAmount - taxAmount

    return Math.round((taxAmount / netAmount) * 1000) / 10
  }

  /**
   * True if enough information is available to calculate one global tax rate
   * for the entire invoice.
   */
  private get canCalculateGlobalTaxRate(): boolean {
    return Boolean(
      this.documentAnalysis.amountTax &&
        this.documentAnalysis.amountTotal &&
        this.documentAnalysis.amountTotal !== 0
    )
  }
}

export default LiveCaptureInvoiceTransformer

import ConfirmDeletionModal from 'components/dialogs/ConfirmDeletionModal'
import { observer } from 'mobx-react'
import Snapshots from 'networking/Snapshots'
import { useRootStore } from 'stores/RootStoreContext'

interface ResetDemoModalProps {
  isOpen: boolean
  onDismiss: () => void
}

const SNAPSHOT_ORGANISATION = '60d48f4297231c2b7826656b'
const SNAPSHOT_NAME = '2024-08-08T21:32:46.846Z'

const ResetDemoModal = (props: ResetDemoModalProps) => {
  const { authenticationStore } = useRootStore()
  const canApplySnapshot =
    authenticationStore.organisationIdentifier === SNAPSHOT_ORGANISATION

  const resetDemo = async () => {
    if (!canApplySnapshot) return
    try {
      await Snapshots.restoreToSnapshot(
        SNAPSHOT_NAME,
        authenticationStore.organisationIdentifier
      )
    } catch (error) {
      console.log(error)
    } finally {
      document.location.href = '/'
    }
  }
  return (
    <ConfirmDeletionModal
      title='Demo zurücksetzen'
      deleteActionCopy='Zurücksetzen'
      isOpen={props.isOpen}
      onDismiss={props.onDismiss}
      onDelete={resetDemo}
      disabled={!canApplySnapshot}
    >
      Hiermit wird die Demo von Examply wieder auf den Ursprungszustand
      zurückgesetzt.
    </ConfirmDeletionModal>
  )
}

export default observer(ResetDemoModal)

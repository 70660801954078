import { IAccount } from '@nextbusiness/infinity-finance-api'
import { Text } from '@nextbusiness/infinity-ui'
import { DemoContext } from 'components/demo/DemoBanner'
import { useContext } from 'react'
import AccountLiveMenuLogo from './AccountLiveMenuLogo'

const DEMO_ACCOUNT_DESIGNATION = 'Firmenkonto UBS'

interface AccountLiveMenuStatusProps {
  account: IAccount
}

const AccountLiveMenuStatus = (props: AccountLiveMenuStatusProps) => {
  const { isDemo } = useContext(DemoContext)
  return (
    <div className='live-accounting-status'>
      <div className='live-accounting-provider'>
        <AccountLiveMenuLogo />
        <div className='provider-depiction'>
          <small>Live Accounting</small>
          <span className='bank-provider'>Verbunden</span>
        </div>
      </div>
      <Text variant='subtle' className='live-accounting-status-text'>
        Verbunden mit «
        {isDemo ? DEMO_ACCOUNT_DESIGNATION : props.account.designation}».
        Letzter Abgleich heute.
      </Text>
    </div>
  )
}

export default AccountLiveMenuStatus

import { Dialog } from '@nextbusiness/infinity-ui'

interface FeatureWaitlistModalProps {
  isOpen: boolean
  onDismiss: () => void
}

const FeatureWaitlistModal = (props: FeatureWaitlistModalProps) => (
  <Dialog {...props} dismissable>
    <div style={{ width: '80rem', height: '72rem', overflowY: 'auto' }}>
      <iframe
        key={String(props.isOpen)}
        src='https://forms.reform.app/Qzb4bn/infinity-finance-feature-waitlist'
        title='Waitlist'
        style={{
          width: '100%',
          marginTop: '-2rem',
          height: '128rem',
          border: 'none',
          outline: 'none',
          overflowY: 'auto',
        }}
      />
    </div>
  </Dialog>
)

export default FeatureWaitlistModal

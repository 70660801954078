import { RadioGroup } from '@nextbusiness/infinity-ui'
import { TemplateInspectorProps } from '../TemplateInspector'
import TemplateInspectorGroup from '../TemplateInspectorGroup'

const TemplateInspectorAddressPosition = (props: TemplateInspectorProps) => (
  <TemplateInspectorGroup>
    <RadioGroup
      options={[
        { label: 'Adresse links', value: 'left' },
        { label: 'Adresse rechts', value: 'right' },
      ]}
      value={props.template.addressLinePosition}
      onChange={(addressLinePosition) =>
        props.modifyTemplate({ addressLinePosition })
      }
    />
  </TemplateInspectorGroup>
)

export default TemplateInspectorAddressPosition

import { InputField, InputFieldProps } from '@nextbusiness/infinity-ui'
import { Icon } from '@nextbusiness/infinity-ui-icons'
import classNames from 'classnames'
import { useRef } from 'react'

interface ResourceSelectInputProps {
  leftAccessory?: React.ReactNode
  displayExpandable?: boolean
  placeholderText: string
  query: string
  onQueryChange: (query: string) => void
  inputFieldProps?: Partial<InputFieldProps>
  isActive?: boolean
  closeFlyout?: () => void
}

const ResourceSelectInput = (props: ResourceSelectInputProps) => {
  const inputRef = useRef<HTMLInputElement>(null)
  return (
    <div
      className={classNames('resource-select-input', {
        'with-left-accessory': props.leftAccessory,
        'with-expand-icon': props.displayExpandable,
      })}
    >
      {props.leftAccessory && (
        <div
          className='left-accessory'
          onClick={() => inputRef.current?.focus()}
          aria-hidden
        >
          {props.leftAccessory}
        </div>
      )}
      <InputField
        placeholder={props.placeholderText}
        ref={inputRef}
        className='resource-select-input'
        value={props.query}
        onChange={props.onQueryChange}
        fullWidth
        {...props.inputFieldProps}
      />
      {props.displayExpandable && (
        <div
          className='expand-icon-container'
          onClick={(e) => {
            e.preventDefault()
            if (props.isActive) props.closeFlyout?.()
            else inputRef.current?.focus()
          }}
          aria-hidden
        >
          <Icon className='expand-icon' icon='expand' size={13} />
        </div>
      )}
    </div>
  )
}

export default ResourceSelectInput

import Filter, { FilterProps } from 'base-components/table/Filter'
import { FilterItem } from 'base-components/table/FilterList'
import { observer } from 'mobx-react'
import { useMemo } from 'react'
import { useRootStore } from 'stores/RootStoreContext'

interface InvoiceContactFilterProps<T extends Record<string, any>>
  extends Omit<FilterProps<string>, 'items'> {
  contactKey: keyof T
  invoices: T[] | undefined
}

const InvoiceContactFilter = <InvoiceType extends Record<string, any>>(
  props: InvoiceContactFilterProps<InvoiceType>
) => {
  const { contactStore } = useRootStore()
  const allContacts = contactStore.allContacts

  const { invoices, contactKey } = props

  const contactFilterItems: FilterItem[] = useMemo(() => {
    const contactsWithInvoices =
      allContacts?.filter((contact) =>
        invoices?.some((invoice) => invoice[contactKey] === contact.id)
      ) ?? []
    console.log({ contactsWithInvoices })
    return contactsWithInvoices.map((contact) => ({
      label: contactStore.displayNameForContactWithId(contact.id),
      value: contact.id,
      icon: 'user',
    }))
  }, [invoices, contactKey, allContacts])

  return (
    <Filter
      icon='address-book'
      items={contactFilterItems}
      sortAlphabetically
      menuSize='wide'
      {...props}
    />
  )
}

export default observer(InvoiceContactFilter)

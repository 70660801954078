import { DemoContext } from 'components/demo/DemoBanner'
import { useContext } from 'react'

const DEMO_PROVIDER_LOGO = '/assets/bank-providers/UBS_rgb.svg'

const AccountLiveMenuLogo = () => {
  const { isDemo } = useContext(DemoContext)
  return (
    <div className='provider-logo'>
      <img
        src={
          isDemo ? DEMO_PROVIDER_LOGO : '/assets/bank-providers/logo-blink.svg'
        }
        alt=''
      />
    </div>
  )
}

export default AccountLiveMenuLogo

import {
  AmountMode,
  ICustomerInvoicePosition,
  ICustomProductPosition,
  IDiscountPosition,
  ITextLinePosition,
  VATPresentationOptions,
} from '@nextbusiness/infinity-finance-api'

export type ICustomerInvoiceColumn =
  | 'quantity'
  | 'unit'
  | 'articleNumber'
  | 'name'
  | 'vatDebt'
  | 'singleAmount'
  | 'totalAmount'

interface ICustomerInvoice {
  id: string
  isDraft: boolean
  recipient: string
  title: string
  openingDate: number
  dueDate: string
  introductoryText?: string | null
  closingText?: string | null
  positions: ICustomerInvoicePosition[]
  totalAmount: number
  totalVat?: number
  payableInDays: number
  invoicesFromQuote?: string[]
  isQrInvoice?: boolean
  totalAmountPaid: number
  discount: number
  totalRestAmount: number
  isPaid: boolean
  openingTransactions: string[]
  paymentTransactions: string[]
  discountTransactions: string[]
  fiscalYear: number
  isQuote: boolean
  createdFromQuote?: string
  template: string
  quoteStatus?: QuoteStatus
  signingPerson?: string
  signedAt?: string
  columns: ICustomerInvoiceColumn[]
  amountMode: AmountMode
  invoiceNumber: number
  isAnnulled?: boolean
  publicPdfUrl?: string
  vatPresentationOptions?: VATPresentationOptions
  shareId?: string
}

export enum QuoteStatus {
  Accepted = 'accepted',
  Rejected = 'rejected',
  Open = 'open',
}

export type ICustomerInvoicePreview = Pick<
  ICustomerInvoice,
  | 'id'
  | 'recipient'
  | 'title'
  | 'isPaid'
  | 'openingDate'
  | 'dueDate'
  | 'totalAmount'
  | 'totalVat'
  | 'isQuote'
  | 'isDraft'
  | 'quoteStatus'
  | 'isAnnulled'
>

export type INewCustomerInvoice = Pick<
  ICustomerInvoice,
  | 'recipient'
  | 'title'
  | 'payableInDays'
  | 'introductoryText'
  | 'closingText'
  | 'isQrInvoice'
  | 'template'
  | 'isQuote'
  | 'isDraft'
  | 'createdFromQuote'
  | 'columns'
  | 'amountMode'
  | 'invoiceNumber'
  | 'vatPresentationOptions'
  | 'shareId'
> & {
  openingDate: number
  positions: INewCustomerInvoicePosition[]
}

export type INewCustomProductPosition = Partial<
  Pick<
    ICustomProductPosition,
    | 'name'
    | 'contraAccount'
    | 'singleAmount'
    | 'quantity'
    | 'taxCode'
    | 'articleNumber'
    | 'unit'
    | 'product'
  >
> & {
  type: 'custom-product'
  identifier: string
}

export type INewDiscountPosition = IDiscountPosition & {
  type: 'discount'
  identifier: string
}

export type INewTextLinePosition = ITextLinePosition & {
  type: 'text-line'
  identifier: string
}

export type INewCustomerInvoicePosition =
  | INewCustomProductPosition
  | INewDiscountPosition
  | INewTextLinePosition

export interface ICustomerInvoiceQueryParams {
  text?: string
  recipient?: string
  openingDate?: number
  dueDate?: number
  totalAmount?: number
  totalVat?: number
  payableInDays?: number
  isQrInvoice?: boolean
  discount?: number
  isPaid?: boolean
  isDraft?: boolean
  tacCode?: string
  articleNumber?: string
  contraAccount?: number
  isQuote?: boolean
}

export interface ICustomerInvoicePayment {
  amount: number
  contraAccount: number
  date: number
}

export default ICustomerInvoice

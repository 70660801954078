import {
  ITransaction,
  TransactionOrigin,
} from '@nextbusiness/infinity-finance-api'
import { Flex } from '@nextbusiness/infinity-ui'
import { useAccountPageContext } from 'ledger/accounts/AccountPageContext'
import { observer } from 'mobx-react'
import { IAccount } from 'model/Account'
import { useState } from 'react'
import { useRootStore } from 'stores/RootStoreContext'
import TransactionDeleteConfirmation from './TransactionDeleteConfirmation'
import './TransactionEditor.scss'
import TransactionEditorBehaviours from './TransactionEditorBehaviours'
import TransactionEditorProvider from './TransactionEditorContext'
import TransactionEditorActions from './actions/TransactionEditorActions'
import TransactionLiveAccountingDetails from './details/TransactionLiveAccountingDetails'
import LiveCaptureAttachment from './details/live-capture/LiveCaptureAttachment'
import TransactionEditorAmount from './fields/TransactionEditorAmount'
import TransactionEditorContraAccount from './fields/TransactionEditorContraAccount'
import TransactionEditorDate from './fields/TransactionEditorDate'
import TransactionEditorDescription from './fields/TransactionEditorDescription'
import TransactionEditorExchangeRate from './fields/TransactionEditorExchangeRate'
import TransactionEditorVATOptions from './options/TransactionEditorVATOptions'

interface TransactionEditorProps {
  currentAccount: IAccount
  transaction: ITransaction
  closeEditor: () => void
  showLiveCaptureAttachment?: boolean
}

const TransactionEditor = (props: TransactionEditorProps) => {
  const { ledgerStore } = useRootStore()
  const { viewId } = useAccountPageContext()

  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState<boolean>(false)

  const closeEditor = () => {
    props.closeEditor()
    ledgerStore.resolveUnsavedEdit(viewId)
  }

  return (
    <TransactionEditorProvider
      closeEditor={closeEditor}
      currentAccount={props.currentAccount}
      isDeletionModalOpen={isDeleteModalOpen}
      openDeletionModal={() => setIsDeleteModalOpen(true)}
      transaction={props.transaction}
    >
      <TransactionEditorBehaviours />
      {props.transaction.origin === TransactionOrigin.LiveAccounting && (
        <TransactionLiveAccountingDetails />
      )}
      <Flex className='upper-half' verticalAlignment='baseline'>
        <TransactionEditorDate />
        <TransactionEditorDescription />
        <TransactionEditorContraAccount />
        <TransactionEditorAmount />
        <TransactionEditorExchangeRate />
      </Flex>
      <Flex className='bottom-half' verticalAlignment='flex-end' gap='tiny'>
        <TransactionEditorVATOptions />
        <TransactionEditorActions />
      </Flex>
      {props.showLiveCaptureAttachment && <LiveCaptureAttachment />}
      {isDeleteModalOpen && (
        <TransactionDeleteConfirmation
          transaction={props.transaction}
          isOpen={isDeleteModalOpen}
          setIsOpen={setIsDeleteModalOpen}
        />
      )}
    </TransactionEditorProvider>
  )
}

export default observer(TransactionEditor)

import { AppMenuProps, ApplicationFrame } from '@nextbusiness/infinity-ui'
import { NavigationTab } from '@nextbusiness/infinity-ui/dist/layout/header/HeaderNavigationTabs'
import { EverythingMenuContext } from 'components/everything-menu/EverythingMenuProvider'
import TimeTrackingPanel from 'components/time-tracking/TimeTrackingPanel'
import { useTimeTrackingIndicator } from 'components/time-tracking/useTimeTrackingIndicator'
import { observer } from 'mobx-react'
import NetworkingConstants from 'networking/NetworkingConstants'
import { useContext } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { useRootStore } from 'stores/RootStoreContext'
import { useUserMenu } from './useUserMenu'

interface CommonApplicationFrameProps {
  children: React.ReactNode
  primaryNavigation?: NavigationTab[]
  appMenu?: AppMenuProps
  overrideIdentifier?: string
}

const CommonApplicationFrame = (props: CommonApplicationFrameProps) => {
  const { accountStore } = useRootStore()
  const menuContext = useContext(EverythingMenuContext)

  const history = useHistory()
  const location = useLocation()

  const showNavigation =
    accountStore.currentFiscalYear && accountStore.allAccounts.length > 0
  const isSetup = location.pathname.startsWith('/setup')

  const userMenu = useUserMenu()

  const {
    isTimeTrackingPanelOpen,
    setTimeTrackingStatusText,
    timeTrackingStatusIndicator,
  } = useTimeTrackingIndicator()

  return (
    <ApplicationFrame
      appTitle='Finance'
      identifier={
        props.overrideIdentifier ?? (isSetup ? 'finance-setup' : 'finance')
      }
      headerProps={{
        onHomeClick: () => {
          document.location.href = NetworkingConstants.HOME + '/dashboard'
        },
        onSearchClick:
          showNavigation && menuContext
            ? () => menuContext?.openMenu()
            : undefined,
        appearance: isSetup ? 'transparent' : 'default',
        status: showNavigation ? timeTrackingStatusIndicator : undefined,
      }}
      userMenu={userMenu}
      primaryNavigation={showNavigation ? props.primaryNavigation : []}
      appMenu={showNavigation ? props.appMenu : undefined}
      history={history}
      location={location}
      rightPanels={
        <TimeTrackingPanel
          isTimeTrackingPanelOpen={isTimeTrackingPanelOpen}
          setTimeTrackingStatusText={setTimeTrackingStatusText}
        />
      }
    >
      {props.children}
    </ApplicationFrame>
  )
}

export default observer(CommonApplicationFrame)

import { Button, Flex } from '@nextbusiness/infinity-ui'
import { Icon, IconSize } from '@nextbusiness/infinity-ui-icons'
import React, { useEffect, useMemo, useRef, useState } from 'react'
import { useHistory } from 'react-router-dom'
import './DemoBanner.scss'
import FeatureWaitlistModal from './FeatureWaitlistModal'
import ResetDemoModal from './ResetDemoModal'

const INACTIVITY_TIMEOUT = 40 * 1000

interface DemoBannerProps {
  children: React.ReactNode
}

export interface DemoContextType {
  isDemo: boolean
  resetDemo?: () => void
}

export const DemoContext = React.createContext<DemoContextType>({
  isDemo: false,
})

const DemoBanner = (props: DemoBannerProps) => {
  const history = useHistory()

  const [isBannerVisible, setIsBannerVisible] = useState<boolean>(true)
  const [isResetDemoModalOpen, setIsResetDemoModalOpen] =
    useState<boolean>(false)
  const [isWaitlistVisible, setIsWaitlistVisible] = useState<boolean>(false)

  const inactivityTimerRef = useRef<any>(null)

  useEffect(() => {
    const moveHandler = () => {
      clearTimeout(inactivityTimerRef.current)
      inactivityTimerRef.current = setTimeout(() => {
        setIsBannerVisible(true)
        setIsResetDemoModalOpen(false)
      }, INACTIVITY_TIMEOUT)
    }
    window.addEventListener('mousemove', moveHandler)
    return () => {
      window.removeEventListener('mousemove', moveHandler)
    }
  }, [])

  const demoContextValue: DemoContextType = useMemo(
    () => ({
      isDemo: true,
      resetDemo: () => setIsResetDemoModalOpen(true),
    }),
    []
  )

  return (
    <DemoContext.Provider value={demoContextValue}>
      {props.children}
      {isBannerVisible && (
        <div
          className='demo-banner'
          onClick={() => {
            setIsBannerVisible(false)
            history.push('/buchen')
          }}
          aria-hidden
        >
          <div className='start-highlight'>
            <Icon icon='cursor' size={64} />
            <span>Klicke, um auszuprobieren</span>
          </div>
        </div>
      )}
      <div
        className='demo-layer'
        style={{
          transform:
            !isBannerVisible && !isWaitlistVisible
              ? 'translateY(0rem)'
              : 'translateY(10rem)',
        }}
      >
        <Flex verticalAlignment='center' gap='small'>
          <Icon icon='cursor' size={IconSize.Medium} />
          <div className='depiction'>
            <b>Willkommen in der Demo von Infinity Finance</b>
            <p>Klicke herum und entdecke die Möglichkeiten.</p>
          </div>
          <Button onClick={() => setIsWaitlistVisible(true)} iconLeft='plus'>
            Benachrichtige mich
          </Button>
        </Flex>
        <ResetDemoModal
          isOpen={isResetDemoModalOpen}
          onDismiss={() => setIsResetDemoModalOpen(false)}
        />
        <FeatureWaitlistModal
          isOpen={isWaitlistVisible}
          onDismiss={() => setIsWaitlistVisible(false)}
        />
      </div>
    </DemoContext.Provider>
  )
}

export default DemoBanner

import VATPeriod from 'model/VAT'
import DocumentsPage from 'pages/DocumentsPage'
import React from 'react'
import VATPeriodSection from './VATPeriodSection'
import VATUnavailablePeriodsSection from './VATUnavailablePeriodsSection'

interface VATPeriodsListProps {
  periods: VATPeriod[]
  page: DocumentsPage
}

const VATPeriodsList = (props: VATPeriodsListProps) => {
  const [relevantPeriods, nonRelevantPeriods] = React.useMemo(() => {
    const relevantPeriods: VATPeriod[] = []
    const nonRelevantPeriods: VATPeriod[] = []

    let hasFoundDuePeriod = false
    const chronologicalPeriods = [...props.periods].reverse()

    for (const period of chronologicalPeriods) {
      const isPeriodDue = period.end < Date.now() && !period.wasSettled
      if (hasFoundDuePeriod) {
        nonRelevantPeriods.push(period)
        continue
      }
      relevantPeriods.push(period)
      if (isPeriodDue) {
        hasFoundDuePeriod = true
      }
    }

    relevantPeriods.reverse()
    nonRelevantPeriods.reverse()

    return [relevantPeriods, nonRelevantPeriods]
  }, [props.periods])

  return (
    <div className='vat-periods'>
      {nonRelevantPeriods.length > 0 && <VATUnavailablePeriodsSection />}
      {relevantPeriods.map((period) => (
        <VATPeriodSection key={period._id} page={props.page} period={period} />
      ))}
    </div>
  )
}

export default VATPeriodsList

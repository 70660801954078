import { Button, Flyout, MenuItems } from '@nextbusiness/infinity-ui'
import { Icon } from '@nextbusiness/infinity-ui-icons'
import { ReactComponent as LiveMenuBadge } from 'assets/live-accounting/live-active.svg'
import MixpanelAnalytics from 'integrations/MixpanelProductAnalytics'
import { useState } from 'react'
import { useAccountPageContext } from '../AccountPageContext'
import { isDefinedAccount } from '../data/AccountRules'
import './AccountLiveMenu.scss'
import AccountLiveMenuDisconnectModal from './AccountLiveMenuDisconnectModal'
import AccountLiveMenuStatus from './AccountLiveMenuStatus'

const AccountLiveMenu = () => {
  const { currentAccount } = useAccountPageContext()
  if (!isDefinedAccount(currentAccount))
    throw new Error(
      'Attempted to render AccountLiveMenu with an unsupported account type (special account).'
    )

  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false)
  const [isDisconnectModalOpen, setIsDisconnectModalOpen] =
    useState<boolean>(false)

  const onTriggerClick = () => {
    if (!isMenuOpen) MixpanelAnalytics.event('Account live menu opened')
    setIsMenuOpen(!isMenuOpen)
  }

  return (
    <Flyout
      className='account-live-menu'
      isActive={isMenuOpen}
      setIsActive={setIsMenuOpen}
      triggerIsButton
      trigger={
        <Button
          onClick={onTriggerClick}
          className='account-live-menu-button'
          variant='quaternary'
          tooltip='Live Accounting-Optionen'
        >
          <LiveMenuBadge className='account-live-menu-badge' />
          <Icon icon='expand' size={12} />
        </Button>
      }
    >
      <AccountLiveMenuStatus account={currentAccount} />
      <MenuItems
        groups={[
          {
            buttons: [
              {
                text: 'Bankverbindung trennen',
                icon: 'disconnected',
                onClick: () => setIsDisconnectModalOpen(true),
              },
            ],
          },
        ]}
        closeMenu={() => setIsMenuOpen(false)}
      />
      <AccountLiveMenuDisconnectModal
        isOpen={isDisconnectModalOpen}
        onDismiss={() => setIsDisconnectModalOpen(false)}
        currentAccount={currentAccount}
      />
    </Flyout>
  )
}

export default AccountLiveMenu

import {
  Finance,
  ITransaction,
  TransactionOrigin,
} from '@nextbusiness/infinity-finance-api'
import { SUPPORTED_FILE_TYPES } from 'documents/document-creator/DocumentUploadConstants'
import MixpanelAnalytics from 'integrations/MixpanelProductAnalytics'
import { useAccountPageContext } from 'ledger/accounts/AccountPageContext'
import { useAccountSheetContext } from 'ledger/accounts/AccountSheetContext'
import { isDefinedAccount } from 'ledger/accounts/data/AccountRules'
import { observer } from 'mobx-react'
import React, { useEffect, useState } from 'react'
import { DropzoneOptions } from 'react-dropzone'
import { useRootStore } from 'stores/RootStoreContext'
import TransactionUtilities from 'utility/TransactionUtilities'
import './Transaction.scss'
import TransactionCard from './TransactionCard'
import TransactionView from './TransactionView'
import { useBatchEditingContext } from './batch-editing/BatchEditingContext'
import TransactionEditor from './editor/TransactionEditor'
import TransactionEditorUtilities from './editor/TransactionEditorUtilities'

interface TransactionProps {
  transaction: ITransaction
  onStartEditing: () => void
  onBatchSelection?: () => void
  isBeingEdited: boolean
  isBeingDeleted: boolean
  closeEditor: () => void
}

const TRANSACTION_CLOSING_ANIMATION_DURATION = 200

const Transaction = (props: TransactionProps) => {
  const { preferencesStore, transactionStore } = useRootStore()
  const { currentAccount } = useAccountPageContext()
  const { highlightedTransactionIds } = useAccountSheetContext()
  const batchEditingContext = useBatchEditingContext()

  const [isOpen, setIsOpen] = useState<boolean>(props.isBeingEdited)

  const isTransactionHighlighted = highlightedTransactionIds?.includes(
    props.transaction.id
  )
  const isUsingTallEditor = TransactionEditorUtilities.requiresTallEditor(
    props.transaction
  )

  const showGrossAmount =
    !preferencesStore.shouldDisplayVATTransactions &&
    TransactionUtilities.doesAccountSupportGrossAmounts(currentAccount)
  const showIndicators = preferencesStore.shouldShowTransactionIndicators()
  const showLiveCaptureAttachment =
    !!props.transaction.attachments?.length &&
    props.transaction.origin === TransactionOrigin.LiveAccounting

  const [isUploading, setIsUploading] = useState<boolean>(false)
  const uploadFile = async (file: File) => {
    if (!preferencesStore.organisationPreferences?.isLiveCaptureEnabled) return
    setIsUploading(true)
    try {
      const { transaction } =
        await Finance.Documents.attachDocumentAndRefineTransaction(
          file,
          currentAccount.accountNumber,
          props.transaction.id
        )
      transactionStore.replaceTransactionsInStore(
        [props.transaction.id],
        [transaction]
      )
    } finally {
      setIsUploading(false)
    }
  }

  const dropzoneOptions: DropzoneOptions = {
    onDrop: (acceptedFiles: File[]) => {
      if (acceptedFiles[0]) uploadFile(acceptedFiles[0])
      MixpanelAnalytics.event('Live Capture ledger attachment uploaded')
    },
    accept: SUPPORTED_FILE_TYPES,
    multiple: false,
  }

  const onTransactionClicked = (
    event: React.MouseEvent | React.KeyboardEvent
  ) => {
    if (batchEditingContext.isInMultiEdit) return props.onBatchSelection?.()
    if (props.isBeingEdited) return
    if (event.ctrlKey || event.metaKey) return props.onBatchSelection?.()
    props.onStartEditing()
  }

  useEffect(() => {
    if (props.isBeingEdited) {
      setIsOpen(true)
    } else {
      setTimeout(() => setIsOpen(false), TRANSACTION_CLOSING_ANIMATION_DURATION)
    }
  }, [props.isBeingEdited])

  return (
    <TransactionCard
      isHighlighted={isTransactionHighlighted}
      isBeingEdited={isOpen}
      isClosing={!props.isBeingEdited && isOpen}
      isBeingDeleted={props.isBeingDeleted}
      origin={props.transaction.origin}
      isTallEditor={isUsingTallEditor}
      hasLiveCaptureAttachment={showLiveCaptureAttachment}
      onClick={onTransactionClicked}
      dropzoneOptions={dropzoneOptions}
      isUploading={isUploading}
    >
      {isOpen && isDefinedAccount(currentAccount) ? (
        <TransactionEditor
          currentAccount={currentAccount}
          transaction={props.transaction}
          closeEditor={props.closeEditor}
          showLiveCaptureAttachment={showLiveCaptureAttachment}
        />
      ) : (
        <TransactionView
          currentAccount={currentAccount}
          transaction={props.transaction}
          interactive
          indicators={showIndicators}
          useGrossAmount={showGrossAmount}
        />
      )}
    </TransactionCard>
  )
}

export default observer(Transaction)

import React, { useContext, useEffect, useMemo, useState } from 'react'

interface AccountSheetContextType {
  isTransactionCreatorOpen: boolean
  highlightedTransactionIds?: string[]
  hasUnsavedChanges: boolean
  transactionBeingEdited?: string
  transactionsToBeDeleted?: string[]
  abortToTransaction?: string
  hasScrolled: boolean
  isHeaderCollapsed: boolean
  hasListReachedBottom: boolean
  setIsTransactionCreatorOpen: (isOpen: boolean) => void
  highlightTransactionWithIds: (transactionIds: string[] | undefined) => void
  setHasUnsavedChanges: (hasUnsavedChanges: boolean) => void
  setTransactionBeingEdited: (transactionId?: string) => void
  setTransactionsToBeDeleted: (transactionIds?: string[]) => void
  setAbortToTransaction: (transactionId?: string) => void
  setHasScrolled: (hasScrolled: boolean) => void
  setIsHeaderCollapsed: (isHeaderCollapsed: boolean) => void
  setHasListReachedBottom: (hasListReachedBottom: boolean) => void
}

export const AccountSheetContext =
  React.createContext<AccountSheetContextType | null>(null)

export const useAccountSheetContext = () => {
  const context = useContext(AccountSheetContext)
  if (!context) {
    throw new Error(
      'useAccountSheetContext must be used within an AccountSheetProvider'
    )
  }
  return context
}

interface AccountSheetProviderProps {
  children: React.ReactNode
}

const AccountSheetProvider = (props: AccountSheetProviderProps) => {
  const [isTransactionCreatorOpen, setIsTransactionCreatorOpen] =
    useState<boolean>(false)
  const [highlightedTransactionIds, setHighlightedTransactionIds] =
    useState<string[]>()
  const [transactionsToBeDeleted, setTransactionsToBeDeleted] =
    useState<string[]>()

  const [hasUnsavedChanges, setHasUnsavedChanges] = useState<boolean>(false)
  const [transactionBeingEdited, setTransactionBeingEdited] = useState<string>()
  const [abortToTransaction, setAbortToTransaction] = useState<string>()

  const [hasScrolled, setHasScrolled] = useState<boolean>(false)
  const [isHeaderCollapsed, setIsHeaderCollapsed] = useState<boolean>(false)
  const [hasListReachedBottom, setHasListReachedBottom] =
    useState<boolean>(false)

  useEffect(() => {
    if (highlightedTransactionIds)
      setTimeout(() => setHighlightedTransactionIds(undefined), 250)
  }, [highlightedTransactionIds])

  const highlightTransactionWithIds = (transactionIds: string[] | undefined) =>
    setHighlightedTransactionIds(transactionIds)

  const value: AccountSheetContextType = useMemo(
    () => ({
      isTransactionCreatorOpen,
      highlightedTransactionIds,
      hasUnsavedChanges,
      transactionBeingEdited,
      transactionsToBeDeleted,
      abortToTransaction,
      hasScrolled,
      isHeaderCollapsed,
      hasListReachedBottom,
      setIsTransactionCreatorOpen,
      highlightTransactionWithIds,
      setHasUnsavedChanges,
      setTransactionBeingEdited,
      setTransactionsToBeDeleted,
      setAbortToTransaction,
      setHasScrolled,
      setIsHeaderCollapsed,
      setHasListReachedBottom,
    }),
    [
      isTransactionCreatorOpen,
      highlightedTransactionIds,
      hasUnsavedChanges,
      transactionBeingEdited,
      transactionsToBeDeleted,
      abortToTransaction,
      hasScrolled,
      isHeaderCollapsed,
      hasListReachedBottom,
    ]
  )

  return (
    <AccountSheetContext.Provider value={value}>
      {props.children}
    </AccountSheetContext.Provider>
  )
}

export default AccountSheetProvider

import { Flex } from '@nextbusiness/infinity-ui'
import { DateTime } from 'luxon'
import VATPeriod, { VATPeriodDuration } from 'model/VAT'
import DocumentsPage from 'pages/DocumentsPage'
import VATPeriodDuePrompt from './VATPeriodDuePrompt'
import VATPeriodNotDuePrompt from './VATPeriodNotDuePrompt'
import './VATPeriodSection.scss'
import VATSettledPeriodSection from './VATSettledPeriodSection'

interface VATPeriodSectionProps {
  page: DocumentsPage
  period: VATPeriod
}

export const displayNameForPeriod = (period: VATPeriod) => {
  const start = DateTime.fromMillis(period.start)
  switch (period.duration) {
    case VATPeriodDuration.HalfYear:
      const halfYear = start.month <= 6 ? 1 : 2
      return `Halbjahr ${halfYear}, ${start.year}`
    case VATPeriodDuration.FullYear:
      return `Jahr ${start.year}`
    case VATPeriodDuration.Quarter:
    default:
      return `Quartal ${start.quarter}, ${start.year}`
  }
}

const VATPeriodSection = (props: VATPeriodSectionProps) => {
  const isPeriodDue = props.period.end < Date.now()
  return (
    <Flex className='vat-period'>
      <div className='period-title'>{displayNameForPeriod(props.period)}</div>
      <div className='period-content'>
        {props.period.wasSettled ? (
          <VATSettledPeriodSection page={props.page} period={props.period} />
        ) : isPeriodDue ? (
          <VATPeriodDuePrompt page={props.page} period={props.period} />
        ) : (
          <VATPeriodNotDuePrompt page={props.page} period={props.period} />
        )}
      </div>
    </Flex>
  )
}

export default VATPeriodSection

import useAutoSuggestVAT from 'ledger/transactions/creator/hooks/useAutoSuggestVAT'
import { useTransactionEditorContext } from './TransactionEditorContext'
import useEditorKeybindings from './hooks/useEditorKeybindings'
import useMarkUnsavedEdit from './hooks/useMarkUnsavedEdit'

const TransactionEditorBehaviours = () => {
  const { draftTransaction, updateDraftTransaction, contraAccountSide } =
    useTransactionEditorContext()

  useEditorKeybindings()
  useAutoSuggestVAT(draftTransaction, updateDraftTransaction, contraAccountSide)
  useMarkUnsavedEdit()

  return null
}

export default TransactionEditorBehaviours

import { Current } from './Authentication'
import NetworkingConstants from './NetworkingConstants'
import NetworkingErrorHandler from './NetworkingErrorHandler'

const restoreToSnapshot = async (
  snapshotName: string,
  organisationId: string,
  forceOverwrite = false
): Promise<void> => {
  const response = await fetch(
    `${NetworkingConstants.HOST}/demo/snapshot/restore/${organisationId}`,
    {
      method: 'POST',
      headers: Current.defaultHeaders,
      body: JSON.stringify({ snapshotName, forceOverwrite }),
    }
  )
  if (!response.ok) {
    NetworkingErrorHandler.throwFromResponse(response)
  }
}

const Snapshots = { restoreToSnapshot }

export default Snapshots

import { Flex, NonIdealState, Text } from '@nextbusiness/infinity-ui'

const MaintenancePage = () => (
  <div className='utility-page page-maintenance'>
    <Flex fillContainer horizontalAlignment='center' verticalAlignment='center'>
      <NonIdealState
        icon='maintenance'
        title='Infinity Finance wird zurzeit gewartet.'
        actions={[
          {
            onClick: () => {
              document.location.href = 'https://status.infinity.swiss/'
            },
            children: 'Zur Statusseite',
            variant: 'primary',
          },
        ]}
      >
        <Flex direction='vertical'>
          <Text type='paragraph'>
            Wir sind momentan daran, unsere Systeme zu aktualisieren. Auf
            unserer Statusseite findest du weitere Informationen.
          </Text>
        </Flex>
      </NonIdealState>
    </Flex>
  </div>
)

export default MaintenancePage
